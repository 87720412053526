import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { InjectLogger, Logger } from '@x/common/log';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthContextService, AuthState } from '../services/auth-context.service';

@Injectable()
export class AuthGuard {
  @InjectLogger()
  log: Logger;

  constructor(
    private router: Router,
    private authContext: AuthContextService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot) {
    return this.authContext.authState().pipe(
      filter((state) => state !== AuthState.LOADING),
      map((state) => {
        if (state === AuthState.OK) {
          return true;
        } else {
          return this.redirectToLogin(routeState);
        }
      }),
    );
  }

  private redirectToLogin(state: RouterStateSnapshot) {
    this.log.warn(
      `User not authenticated (state=${this.authContext.currentAuthState}) for route '${state.url}', redirecting to login`,
    );
    return this.router.parseUrl('/auth/login?returnUrl=' + state.url);
  }
}
